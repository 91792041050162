<template>
    <article id="page-map">
      <div class="toolbar clearfix">
      <div class="search-wrap pull-left">
        <div class="search-box">
          <a-input-search
            placeholder="请输入地点名称/机构名称"
            enter-button
            v-model="providerQuery.provider_name"
            @search="onSearch" />
          <div class="search-icon"><i class="iconfont icon-search"></i></div>
        </div>
      </div>
      <div class="z-wrap pull-left">
        <div class="toolbar-tlist" style="width: 150px">
          <span class="name">{{provider_type ? provider_type : '机构类型'}}<a-icon type="down" /></span>
          <div class="list-wrap-scroll">
            <ul class="toolbar-list-wrap">
              <li class="list">
                <span  @click="changeProtype('法律援助中心')" textname="法律援助中心">法律援助中心</span>
              </li>
              <li class="list">
                <span  @click="changeProtype('人民调解机构')" name="5" textname="人民调解机构">人民调解机构</span>
              </li>
              <li class="list">
                <span @click="changeProtype('司法鉴定机构')" name="3" textname="司法鉴定机构">司法鉴定机构</span>
              </li>
              <li class="list">
                <span @click="changeProtype('公证机构')" name="2" textname="公证处">公证处</span>
              </li>
              <li class="list">
                <span  @click="changeProtype('律师事务所')" name="1" textname="律师事务所">律师事务所</span>
              </li>
              <li class="list">
                <p  @click="changeProtype('仲裁服务机构')" name="7" textname="仲裁机构">仲裁机构</p>
              </li>
              <li class="list">
                <p  @click="changeProtype('基层法律服务所')" name="8" textname="基层法律服务">基层法律服务</p>
              </li>
              <li class="list">
                <span @click="changeProtype('司法行政机构')" name="6" textname="司法行政机构">司法行政机构</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="z-wrap pull-left">
        <div class="toolbar-tlist">
          <span class="name">{{city ? city : '市区'}}<a-icon type="down" /></span>
          <div class="list-wrap-scroll">
          <Collection  name="city"  v-slot="{ collection }" >
            <ul class="toolbar-list-wrap">
                <li class="list" @click="handleChange('', '')"><span>全部区域</span></li>
                <li class="list" @click="handleChange(value.district_name, value.district_id)" v-for="value in collection" :key="value.district_id"  :value="value.district_id">
                  {{value.district_name}}
                </li>
            </ul>
             </Collection>
          </div>
        </div>
      </div>
      <div class="z-wrap pull-left" v-if="citykey !== ''">
        <div class="toolbar-tlist">
          <span class="name">{{area ? area : '县'}}<a-icon type="down" /></span>
          <div class="list-wrap-scroll">
          <Collection  name="area"  v-slot="{ collection }" :filter="{ parent: citykey }" >
            <ul class="toolbar-list-wrap">
                <li class="list" @click="areaChange('','')"><span>全部区域</span></li>
                <li class="list" @click="areaChange(value.district_name, value.district_id)" v-for="value in collection" :key="value.district_id"  :value="value.district_id">
                  {{value.district_name}}
                </li>
            </ul>
             </Collection>
          </div>
        </div>
      </div>
    </div>
        <div id="left" class="mapMain_left p-3" v-if="showdet == 1">
         <!-- <div>
            <a-input-search
            placeholder="请输入地点名称/机构名称"
            enter-button
            v-model="providerQuery.provider_name"
            @search="onSearch" />
          </div>
          <div class="ggflfwpt_select mt-2">
            <div class="city">
              <div class="ggflfwpt_type mb-2">请选择地区</div>
              <CityMap @change="handleCityChange"></CityMap>
            </div>
            <div class="county" style="text-align: center; margin: 0px auto; display: none;"> </div>
          </div>
          <div class="ggflfwpt_type">请选择司法服务类型</div>
          <div class="choose_institutions">
            <ul class="findLXUL mt-3 mb-3">
              <li class="fyzxLI">
                <div @click="changeProtype('')"><img src="../../assets/images/ggflfwpt_02.png"></div>
                <p name="4" textname="法律援助中心">法律援助中心</p>
              </li>
              <li class="fyzxLI">
                <div @click="changeProtype('人民调解机构')"><img src="../../assets/images/ggflfwpt_03.png"></div>
                <p name="5" textname="人民调解机构">人民调解机构</p>
              </li>
              <li class="fyzxLI">
                <div @click="changeProtype('司法鉴定机构')"><img src="../../assets/images/ggflfwpt_04.png"></div>
                <p name="3" textname="司法鉴定机构">司法鉴定机构</p>
              </li>
              <li class="fyzxLI">
                <div @click="changeProtype('公证机构')"><img src="../../assets/images/ggflfwpt_05.png"></div>
                <p name="2" textname="公证处">公证处</p>
              </li>
              <li class="fyzxLI">
                <div @click="changeProtype('律师事务所')"><img src="../../assets/images/ggflfwpt_06.png"></div>
                <p name="1" textname="律师事务所">律师事务所</p>
              </li>
              <li class="fyzxLI">
                <div @click="changeProtype('仲裁服务机构')"><img src="../../assets/images/ggflfwpt_07.png"></div>
                <p name="7" textname="仲裁机构">仲裁机构</p>
              </li>
              <li class="fyzxLI">
                <div @click="changeProtype('基层法律服务所')"><img src="../../assets/images/ggflfwpt_08.png"></div>
                <p name="8" textname="基层法律服务">基层法律服务</p>
              </li>
              <li class="fyzxLI">
                <div @click="changeProtype('司法行政机构')"><img src="../../assets/images/ggflfwpt_09.png"></div>
                <p name="6" textname="司法行政机构">司法行政机构</p>
              </li>
            </ul>
          </div>-->
          <div class='show_wrap' >
                  <div class="itempro"
                    v-for="(item,index) in prodata"
                    :key="item.provider_id"
                    @click="showInfo(index)"
                  >
                    <p class="name"><a-icon type="bank" />{{ item.provider_name }}</p>
                    <p class="address"><a-icon type="environment" />{{ item.address }}</p>
                    <p class="phone"><a-icon type="phone" />{{ item.phone }}</p>
                    <a-icon type="right" />
                  </div>
                  <div class="page">
                  <a-pagination
                    show-quick-jumper
                    :current="provider.current"
                    :defaultPageSize="provider.defaultPageSize"
                    :total="count"
                    @change="onChange"
                />
                  </div>
          </div>
        </div>
        <div>
          <div id="container" style="width:100%;height:1000px;"></div>
          </div>
      </article>
</template>
<script>
import maps from 'qqmap'
import Collection from '../../components/Collection.vue'
export default {
  name: 'legalMap',
  components: {
    Collection
  },
  data () {
    return {
      map: null,
      // 经度
      longitude: '103.842232',
      // 纬度
      latitude: '36.051392',
      addressKeyword: '',
      marker: '',
      count: 0,
      provider: {
        current: 1,
        defaultPageSize: 9
      },
      providerQuery: {
        city: '',
        area: '',
        provider_name: ''
      },
      city: '地区',
      citykey: '',
      show: false,
      // 机构类型
      provider_type: '',
      showdet: 0,
      prodata: '',
      showOrg: 0
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      var that = this
      maps.init('4BGBZ-6YE6D-MZ34J-P5MMT-C2F7H-7BBM5', () => {
        // 定位的经纬度
        var center = new maps.LatLng(that.latitude, that.longitude)
        that.center = center
        console.log(center)
        // 显示初始化地图
        var myOptions = {
          zoom: 16,
          center: center,
          mapTypeId: maps.MapTypeId.ROADMAP,
          mapStyleId: 'style1',
          panControl: true,
          panControlOptions: {
            position: maps.ControlPosition.TOP_RIGHT
          },
          zoomControl: true,
          zoomControlOptions: {
            // 设置地图控件位置靠近左侧
            position: maps.ControlPosition.TOP_RIGHT
          },
          mapTypeControlOptions: {
            // 设置地图控件位置靠近顶部
            position: maps.ControlPosition.TOP_CENTER
          }
        }
        that.map = new maps.Map(
          document.getElementById('container'),
          myOptions
        )
      })
    },
    onChange (page, pageSize) {
      this.provider.current = this.provider.limit = page
    },
    onSearch (value) {
      this.orgList()
    },
    orgList () {
      var that = this
      that.showdet = 1
      this.$api.get('/api/provider', {
        params: this.providerQuery
      }).then((res) => {
        var org = res.data.collection
        that.prodata = org
        that.count = res.data.count

        // 纬度
        that.latitude = org.latitude
        // 经度
        that.longitude = org.longitude
        var center = new maps.LatLng(org.latitude, org.longitude)
        // 文字
        that.marker = new maps.Label({
          position: center,
          map: that.map,
          // 标注的文字信息
          content: org.provider_name
        })
        // 图标
        that.marker = new maps.Marker({
          position: center,
          map: that.map
        })
        // org.forEach(element => {
        //   // 纬度
        //   that.latitude = element.latitude
        //   // 经度
        //   that.longitude = element.longitude
        //   var center = new maps.LatLng(element.latitude, element.longitude)
        //   // 文字
        //   that.marker = new maps.Label({
        //     position: center,
        //     map: that.map,
        //     // 标注的文字信息
        //     content: element.provider_name
        //   })
        //   // 图标
        //   that.marker = new maps.Marker({
        //     position: center,
        //     map: that.map
        //   })
        // })
      })
      that.init()
    },
    handleChange (name, key) {
      this.city = name
      this.providerQuery.city = name
      this.citykey = key
      this.orgList()
    },
    areaChange (name, key) {
      this.area = name
      this.providerQuery.area = name
      this.orgList()
    },
    // 法律援助中心
    changeProtype (type) {
      this.providerQuery.provider_type = type
      this.provider_type = type
      this.orgList()
    },
    handleCityChange () {
    },
    showInfo (index) {
      var that = this
      that.showOrg = 1
      var prodatainfo = that.prodata[index]
      that.latitude = that.prodata[index].latitude
      that.longitude = that.prodata[index].longitude
      var str = '<div class="amap-info-content amap-info-outer"><div class="card"><div class="card-heading">'
      str += '<div class="tag">' + prodatainfo.provider_type + '</div></div>'
      str += '<div class="card-body"><div class="title"><a target="_blank" href=/www/#/orgdetails/' + prodatainfo.provider_id + '><span>' + prodatainfo.provider_name + '</span></a>'
      str += '</div><div class="tel-phone-box"><div class="tel-phone-title"><i class="iconfont icon-tel"></i>服务电话</div>'
      str += '<div class="tel-phone">' + prodatainfo.phone + '</div></div><div class="desc"><i class="iconfont icon-local"></i>' + prodatainfo.address + '</div>'
      str += '</div></div></div>'
      maps.init('4BGBZ-6YE6D-MZ34J-P5MMT-C2F7H-7BBM5', () => {
        // 定位的经纬度
        var center = new maps.LatLng(that.latitude, that.longitude)
        console.log(center)
        // 显示初始化地图
        var myOptions = {
          zoom: 16,
          center: center,
          mapTypeId: maps.MapTypeId.ROADMAP,
          mapStyleId: 'style1'
        }
        that.map = new maps.Map(
          document.getElementById('container'),
          myOptions
        )
        var info = new maps.InfoWindow({
          map: that.map
        })
        info.open()
        info.setContent(str)
        info.setPosition(center)
      })
    }
  }
}
</script>
<style scoped>
@import url("./../../assets/css/map.css");
</style>
